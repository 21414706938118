import React, { useContext } from 'react'
import { Avatar, Layout, Space } from 'antd'
import styled, { ThemeContext } from 'styled-components'
import useUserInfo from 'containers/hooks/useUserInfo'

import { MLinkRouterButton, Text14White, Text20Normal, Text20White } from 'components/@atomics'
import ROUTES from '@constants/constRoutes'

const LayoutSider = () => {
  const themeContext = useContext(ThemeContext)
  const getBackgroundColor = (fitpetmallEnv?: string) => {
    switch (fitpetmallEnv) {
      case 'development':
        return '#87CEEB'
      case 'alpha':
        return '#51cf66'
      case 'staging':
        return '#fcc419'
      case 'rc':
        return '#e3765c'
      default:
        return undefined
    }
  }
  const developmentBackgroundColor = getBackgroundColor(process.env.FITPETMALL_ENV)
  const menuSider = useUserInfo.useMenuSider()

  return (
    <StyledSider
      width={themeContext.sider.width}
      trigger={null}
      collapsible
      collapsed={false}
      onBreakpoint={(broken) => {
        console.log(broken)
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type)
      }}
      style={{ backgroundColor: developmentBackgroundColor }}
    >
      <>
        <StyledSiderHeader>
          <MLinkRouterButton href={ROUTES.HOME}>
            <Text20White>Fitpet Mall Admin</Text20White>
          </MLinkRouterButton>
        </StyledSiderHeader>
        <StyledSpaceSiderHeaderLogo
          align="center"
          direction="vertical"
          style={{ backgroundColor: developmentBackgroundColor }}
        >
          <MLinkRouterButton href={ROUTES.HOME}>
            <Avatar style={{ backgroundColor: '#ffffff' }} size={68}>
              <Text20Normal>F</Text20Normal>
            </Avatar>
          </MLinkRouterButton>
          <Text14White>FITPET MALL</Text14White>
        </StyledSpaceSiderHeaderLogo>
      </>
      {menuSider}
    </StyledSider>
  )
}

const StyledSider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35);
`

const StyledSiderHeader = styled.div`
  margin: 16px;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
`

const StyledSpaceSiderHeaderLogo = styled(Space)`
  width: 100%;
  margin: 16px 0;
  background: #001529;
`

export default LayoutSider
