import { Layout } from 'antd'
import styled from 'styled-components'

import { LayoutFooter, LayoutHeader } from 'domains/common/layouts'
import { MBreadcrumb } from 'components/@atomics'

export type Props = {
  toolbar?: JSX.Element
  content: JSX.Element
  footer?: JSX.Element
}

function LayoutMain(props: Props) {
  const { toolbar, content, footer } = props
  return (
    <Layout>
      <LayoutHeader />
      <StyledLayoutContent>
        <MBreadcrumb />
        {toolbar}
        {content}
      </StyledLayoutContent>
      <LayoutFooter>{footer}</LayoutFooter>
    </Layout>
  )
}

const StyledLayoutContent = styled(Layout.Content)`
  min-height: calc(100vh - ${(props) => props.theme.header.height}px);
`

export default LayoutMain
