import _ from 'lodash'
import { useRouter } from 'next/router'
import { Layout, Row, Col, Popconfirm } from 'antd'
import { MoreOutlined, CheckOutlined } from '@ant-design/icons'
import styled, { createGlobalStyle } from 'styled-components'

import * as utilData from '@libs/utils/utilData'
import { CIRCLE_BUTTON_COLOR } from '@constants/constData'
import { themes } from '@constants/themes'
import ROUTES from '@constants/constRoutes'
import { MDropdown, MButton, MDivider } from 'components/@atomics'
import useUserInfo, { roleInfoProps } from 'containers/hooks/useUserInfo'
import { MenuHeaderProfile } from 'domains/common/menus'

function LayoutHeader() {
  const router = useRouter()
  const whoamiData = useUserInfo.useWhoamiData()
  const { roleInfos, currentRoleInfo } = useUserInfo.useCurrentUserData()
  const { saveCurrentRole } = useUserInfo.useSaveUserInfo()

  const setCircleButtonArr = (): roleInfoProps[] => {
    const filteredUserNames: roleInfoProps[] = _.filter(roleInfos, (user) => user.name !== currentRoleInfo!.name)
    return _.slice(filteredUserNames, 0, 2)
  }

  const getCircleButtonText = (name: string): string => name.toString().charAt(0)

  const setCircleButtonStyles = (type: keyof typeof CIRCLE_BUTTON_COLOR): {} => {
    return {
      color: themes.colors.white,
      backgroundColor: CIRCLE_BUTTON_COLOR[type] as unknown as string,
      marginRight: '4px',
    }
  }

  const setCircleButtons = (circleButtonArr: roleInfoProps[], idx: number): JSX.Element | false => {
    if (circleButtonArr.length <= idx) return false
    const roleInfo = circleButtonArr[idx]
    return (
      circleButtonArr.length > idx && (
        <Col span={1}>
          <MButton
            shape="circle"
            onClick={() => {
              saveCurrentRole(roleInfo)
              router.push({ pathname: ROUTES.DASHBOARD })
            }}
            style={setCircleButtonStyles(roleInfo.type as keyof typeof CIRCLE_BUTTON_COLOR)}
          >
            {getCircleButtonText(roleInfo.name)}
          </MButton>
        </Col>
      )
    )
  }

  const setListButtons = (): JSX.Element[] => {
    const setList = (userName: roleInfoProps): JSX.Element => {
      const userNamesIdx = _.findIndex(roleInfos, ['key', userName.key])
      return (
        <StyledListButtonRow key={userName.key}>
          <Col>{setCircleButtons(roleInfos, userNamesIdx)}</Col>
          <Col>{userName.name}</Col>
          {currentRoleInfo!.name === userName.name && (
            <Col>
              <StyledCheckIcon />
            </Col>
          )}
          <MDivider dashed style={{ width: '100%', margin: '5px 0px 0px 0px' }} />
        </StyledListButtonRow>
      )
    }
    return _.map(roleInfos, setList)
  }

  // const handleAlarmClick = () => {
  //   router.push({
  //     pathname: ROUTES.ALARM_LIST,
  //     query: { roleType: currentRoleInfo.type, id: currentRoleInfo.key },
  //   })
  // }

  if (!utilData.hasRole(currentRoleInfo) || !utilData.hasData(whoamiData)) return null

  const userData = whoamiData.user.data

  return (
    <StyledHeader>
      <StyledRow justify="end">
        <Col>
          <MButton
            shape="round"
            style={setCircleButtonStyles(currentRoleInfo.type as keyof typeof CIRCLE_BUTTON_COLOR)}
            // onClick={handleAlarmClick}
          >
            {currentRoleInfo.name}
          </MButton>
        </Col>
        {setCircleButtons(setCircleButtonArr(), 0)}
        {setCircleButtons(setCircleButtonArr(), 1)}

        {roleInfos.length > 3 && (
          <>
            <Col>
              <Popconfirm title={setListButtons()} icon={false} okText={<></>} cancelText={<></>}>
                <MButton
                  shape="circle"
                  type="text"
                  icon={<MoreOutlined style={{ fontSize: '18px' }} />}
                  style={{ backgroundColor: themes.colors.blackAlpha6, marginRight: '20px' }}
                />
              </Popconfirm>
            </Col>
            <GlobalStyle />
          </>
        )}
        <Col>
          <MDropdown wordProps={{ text: userData.email }} menu={<MenuHeaderProfile />} />
        </Col>
      </StyledRow>
    </StyledHeader>
  )
}

const StyledHeader = styled(Layout.Header)`
  width: calc(100vw - ${(props) => props.theme.sider.width}px);
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  z-index: 1000;
  position: fixed;
  background-color: #ffffff;
  text-align: center;
`

const StyledRow = styled(Row)`
  height: ${(props) => props.theme.header.height}px;
`

const StyledListButtonRow = styled(Row)`
  padding: 5px 20px 0px 10px;
  align-items: center;
`

const StyledCheckIcon = styled(CheckOutlined)`
  color: ${themes.colors.red};
  margin-left: 10px;
`

const GlobalStyle = createGlobalStyle`
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-message {
    padding: 0;
  }

  .ant-popover-message-title {
    padding-left: 0;
  }

  .ant-popover-buttons {
    height: 0;
  }
`

export default LayoutHeader
