import { ReactNode } from 'react'
import { Layout } from 'antd'

type Props = {
  children?: ReactNode
}

function LayoutFooter(props: Props) {
  const { children } = props
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      {children || 'Fitpet Admin Design©2021 Created by Fitpet'}
    </Layout.Footer>
  )
}

export default LayoutFooter
