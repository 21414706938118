import { FC, ReactNode, useContext } from 'react'
import { Layout } from 'antd'
import { ThemeContext } from 'styled-components'

import { LayoutSider } from 'domains/common/layouts'
import * as utilCommon from '@libs/utils/utilCommon'
import { useRouter } from 'next/router'

type Props = {
  children: ReactNode
}

const LayoutPage: FC<Props> = (props) => {
  const { children } = props
  const themeContext = useContext(ThemeContext)
  const router = useRouter()

  utilCommon.checkPreviousPageIsDetail(router.pathname)

  return (
    <Layout>
      <Layout style={{ marginLeft: themeContext.sider.width }}>
        <LayoutSider />
        {children}
      </Layout>
    </Layout>
  )
}

export default LayoutPage
